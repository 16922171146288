var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"col-md-12",staticStyle:{"padding":"0px !important"}},[_c('b-table',{ref:"table",staticClass:"mmsett",attrs:{"current-page":_vm.currentPage,"per-page":_vm.perPage,"tbody-tr-class":'transparentTr',"fields":_vm.fields,"show-empty":"","items":_vm.itemsProvider},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center"},[(_vm.processing)?_c('div',{staticClass:"text-center my-2",staticStyle:{"color":"#55904e"}},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1):_c('div',[_vm._v("No Properties Found")])])]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2",staticStyle:{"color":"#55904e"}},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(custom)",fn:function(row){return [_c('simple-list-property',{key:row.item.id,attrs:{"observer":_vm.observer,"property":row.item,"offerTypes":_vm.offerTypes},scopedSlots:_vm._u([{key:"descriptionTitle",fn:function(ref){
var property = ref.property;
return [_c('span',{staticClass:"mt-n4",staticStyle:{"text-align":"justify","margin-top":"2px !important"}},[_vm._v(" "+_vm._s(property.description ? property.description[0] ? ("" + (_vm.sliceString( property.description[0].value[Object.keys(property.description[0].value)[0]] ))) : "" : "")),(
                  (property.description
                    ? property.description[0]
                      ? property.description[0].value[Object.keys(property.description[0].value)[0]]
                      : ''
                    : ''
                  ).length > 250
                )?_c('router-link',{attrs:{"to":("/property-details/" + (property.id) + "/?read-more")}},[_vm._v("...more")]):_vm._e()],1)]}}],null,true)})]}}])}),_c('div',{staticClass:"d-flex justify-content-center"},[(!_vm.processing)?_c('b-pagination',{ref:"pagination",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"my-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }