<template>
  <!-- <div> -->
  <div class="container">
    <div style="padding: 0px !important" class="col-md-12">
      <b-table
        class="mmsett"
        ref="table"
        :current-page="currentPage"
        :per-page="perPage"
        :tbody-tr-class="'transparentTr'"
        :fields="fields"
        show-empty
        :items="itemsProvider"
      >
        <template #empty>
          <div class="d-flex justify-content-center">
            <div
              v-if="processing"
              style="color: #55904e"
              class="text-center my-2"
            >
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
            <div v-else>No Properties Found</div>
          </div>
        </template>
        <template #table-busy>
          <div style="color: #55904e" class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(custom)="row">
          <simple-list-property
            :observer="observer"
            :key="row.item.id"
            :property="row.item"
            :offerTypes="offerTypes"
          >
            <template #descriptionTitle="{ property }">
              <span
                style="text-align: justify; margin-top: 2px !important"
                class="mt-n4"
              >
                {{
                  property.description
                    ? property.description[0]
                      ? `${sliceString(
                          property.description[0].value[Object.keys(property.description[0].value)[0]]
                        )}`
                      : ""
                    : ""
                }}<router-link
                  v-if="
                    (property.description
                      ? property.description[0]
                        ? property.description[0].value[Object.keys(property.description[0].value)[0]]
                        : ''
                      : ''
                    ).length > 250
                  "
                  :to="`/property-details/${property.id}/?read-more`"
                  >...more</router-link
                ></span
              >
            </template>
          </simple-list-property>
        </template>
      </b-table>
      <div class="d-flex justify-content-center">
        <b-pagination
          v-if="!processing"
          ref="pagination"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
const simpleListProperty = () => import(/* webpackChunkName: "Ai9EwpdUhw" */ "../components/simple-list-property");
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      hasPageChanged: false,
      offerTypes: [],
      observer: null,
      isSortDesc: true,
      sortOrder: "desc",
      sortItem: "id",
      isBusy: true,
      currentPage: this.$route.query.page || 2,
      rows: 10,
      perPage: 10,
      fields: [
        {
          key: "custom",
          thClass: ["d-none"],
          tdClass: "remove-border",
        },
      ],
    };
  },
  props: [
    "properties",
    "paginationData",
    "selectPerPage",
    "sorting",
    "search",
    "processing",
    "busy",
  ],
  watch: {
    busy(val){
      this.isBusy = val
    },
    $route(val) {
      if (!Object.keys(val.query).length) this.$refs.table.refresh();
    },
    currentPage(val) {
      if (this.isQueryPresent() && this.hasPageChanged)
        this.$emit("pageChanged", val);
    },
    properties(val) {
      this.rows = val.length;
      if (Object.keys(this.$route.query).length) this.$refs.table.refresh();
    },
    search(val) {
      this.search = val;
    },
    selectPerPage(val) {
      if (val !== this.perPage) {
        this.perPage = val;
      }
    },
    paginationData(val) {
      this.rows = val ? val.total : 10;
      this.currentPage = val ? val.currentPage : 1;
      this.perPage = val ? val.perPage : 10;
    },
  },
  computed: {
    ...mapGetters({
      propertiesWithPagination: "getProperties",
      auth: "auth",
    }),
  },
  created() {
    let observerConfig = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    this.observer = new IntersectionObserver(this.observerFn, observerConfig);
  },
  mounted() {
    this.$store
      .dispatch("getConfigurationsByKey", "offerType")
      .then((res) => {
        if (res.data.success) {
          this.offerTypes = Object.keys(res.data.data.val);
        }
      });
    this.rows = this.paginationData ? this.paginationData.total : 10;
    this.currentPage = this.paginationData
      ? this.paginationData.currentPage
      : 1;
    this.perPage = this.paginationData ? this.paginationData.perPage : 10;
  },
  methods: {
    observerFn(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }
        this.$store.dispatch("traceIp").then((res) => {
          console.log(res, "trace");
          const data = {
            propertyId: target.dataset.propertyId,
            userId: Object.keys(this.auth).length ? this.auth.id : "",
            ip: res.ip,
          };

          this.$store
            .dispatch("createPropertyView", data)
            .then((res) => {
              if (res == true) {
                console.log(res);
              } else this.observer.unobserve(target);
            });
        });
      });
    },
    isQueryPresent() {
      if (Object.keys(this.$route.query).length) return true;
      else return false;
    },
    sliceString(str) {
      if (str) {
        if (str.length > 280) {
          this.shouldShow = true;
          return str.slice(0, 280);
        } else return str;
      } else return "";
    },
    itemsProvider(ctx, callback) {
      console.log("here", this.properties)
      this.hasPageChanged = false;
      document.querySelector(".hello-world");
      if (Object.keys(this.$route.query).length) {
        this.hasPageChanged = true;
        return this.properties || [];
      } 
      return [];
    },
  },
  components: {
    simpleListProperty,
  },
};
</script>

<style scoped>
@import url("../assets/css/fontawesome-all.css");
@import url("../assets/css/fontawesome-5-all.min.css");
@import url("../assets/css/font-awesome.min.css");
@import url("../assets/css/styles.css");


@media (min-width: 400px) and (max-width: 700px) {
  .mmsett {
    margin-bottom: 0px !important;
  }
}

div >>> .page-item.active .page-link {
  background-color: #55904e;
  border-color: #55904e;
}
div >>> .page-link {
  color: #55904e;
}
div >>> .remove-border {
  border-top: none;
  padding: 0px;
}
.transparentTr {
  background-color: transparent !important;
}
</style>
